import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["minPay", "maxPay", "payTypeContainer", "output"]

  get payType() {
    return this.payTypeContainerTarget.querySelector("input:checked")?.value
  }

  update() {
    if (!this.hasOutputTarget) return

    const sections = []

    if (this.minPayTarget.value || this.maxPayTarget.value) {
      if (this.minPayTarget.value && this.maxPayTarget.value && this.minPayTarget.value !== this.maxPayTarget.value) {
        sections.push(`$${this.minPayTarget.value} - $${this.maxPayTarget.value}`)
      } else if (this.minPayTarget.value) {
        sections.push(`$${this.minPayTarget.value}`)
      } else if (this.maxPayTarget.value) {
        sections.push(`$${this.maxPayTarget.value}`)
      }

      if (this.payType) {
        switch (this.payType) {
          case "hourly_rate":
            sections.push("per hour")
            break
          case "monthly_salary":
            sections.push("per month")
            break
          case "annual_salary":
            sections.push("per year")
            break
          case "annual_commission":
            sections.push("per year, plus commission")
            break
        }
      }
    } else if (this.payType) {
      switch (this.payType) {
        case "hourly_rate":
          sections.push("Hourly rate")
          break
        case "monthly_salary":
          sections.push("Monthly salary")
          break
        case "annual_salary":
          sections.push("Annual salary")
          break
        case "annual_commission":
          sections.push("Annual salary with commission")
          break
      }
    }

    this.outputTarget.value = sections.join(" ")
  }
}
