import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["dateInputs", "selectInputs"]

  showSelectInputs() {
    if (this.selectInputsTarget) {
      this.selectInputsTarget.classList.remove("hidden")
      this.selectInputsTarget.removeAttribute("disabled")
    }
    if (this.dateInputsTarget) {
      this.dateInputsTarget.classList.add("hidden")
      this.dateInputsTarget.setAttribute("disabled", "true")
    }
  }

  showDateInputs() {
    if (this.dateInputsTarget) {
      this.dateInputsTarget.classList.remove("hidden")
      this.dateInputsTarget.removeAttribute("disabled")
    }
    if (this.selectInputsTarget) {
      this.selectInputsTarget.classList.add("hidden")
      this.selectInputsTarget.setAttribute("disabled", "true")
    }
  }

  toggleDateFields(event) {
    if (event.target.value === "payment_date") {
      this.showDateInputs()
    } else {
      this.showSelectInputs()
    }
  }
}
