import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    idMapping: Object,
  }

  static targets = ["categoryField"]

  #previousSubCategory

  connect() {
    this.updateVisible()
  }

  updateVisible() {
    const category = this.categoryFieldTarget.value
    const subCategoryId = this.idMappingValue[category]

    this.#previousSubCategory?.classList.add("hidden")
    const subCategorySelect = document.getElementById(subCategoryId)
    subCategorySelect?.classList.remove("hidden")
    this.#previousSubCategory = subCategorySelect
  }
}
