import { Controller } from "@hotwired/stimulus"

// This is a collection of hotjar Events to track
// Use this for tailwind-supported (non-React) pages
// each method should be its own tracker; do not combine trackers in a single method
export default class extends Controller {
  track_revert_pay_run() {
    window.hj && window.hj("event", "revert_pay_run")
  }
}
