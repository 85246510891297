import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["rowSelector", "columnSelector"]

  connect() {
    this.preventRowsAndColumnDuplicateSelections(this.rowSelectorTarget)
    this.preventRowsAndColumnDuplicateSelections(this.columnSelectorTarget)
  }

  updateHeaderSelections(e) {
    this.preventRowsAndColumnDuplicateSelections(e.target)
  }

  preventRowsAndColumnDuplicateSelections(headerSelector) {
    let otherHeaderSelector
    if (headerSelector === this.rowSelectorTarget) {
      otherHeaderSelector = this.columnSelectorTarget
    } else {
      otherHeaderSelector = this.rowSelectorTarget
    }

    otherHeaderSelector.options.forEach((option) => {
      if (option.value === headerSelector.value) {
        option.disabled = true
      } else {
        option.disabled = false
      }
    })
  }
}
