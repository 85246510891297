import { Controller } from "@hotwired/stimulus"
import * as Turbo from "@hotwired/turbo"

export default class extends Controller {
  static targets = ["content", "filtersForm", "sortBy"]

  disableTableWhileUpdating() {
    this.contentTarget.classList.add("animate-pulse", "cursor-wait", "opacity-60")
  }

  applyFilters(event) {
    const debounceAmount = event.target.type === "search" ? 300 : 0
    const debouncedFilter = debounce(() => {
      this.disableTableWhileUpdating()
      const mainUrl = new URL(window.origin + window.location.pathname)
      const formData = new FormData(this.filtersFormTarget)
      formData.forEach((value, key) => {
        if (key === "authenticity_token") return
        mainUrl.searchParams.append(key, value)
      })
      Turbo.visit(mainUrl.toString())
    }, debounceAmount)
    debouncedFilter(event)
  }

  updateSortByAndApplyFilters(event) {
    this.sortByTarget.value = event.target.value
    this.applyFilters(event)
  }
}

function debounce(func, delay) {
  let timeoutId
  return function (...args) {
    clearTimeout(timeoutId)
    timeoutId = setTimeout(() => {
      func.apply(this, args)
    }, delay)
  }
}
