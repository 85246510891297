import { Controller } from "@hotwired/stimulus"
import Request from "helpers/request"
import { disableSubmit } from "stimulus/shared_controllers/form_controller"

/*
Attempts to log in to all regions provided by `baseUrls`.

See https://github.com/TandaHQ/employee/blob/4de134a2834161f7abb18effd46bf40763c72203/src/store/thunks/fetch.js#L72 for prior art.

If a region authenticates successfully:
- If nativeProvideUserAuthDetails exists, it gets called, and the native app takes over.
-- The native app will probably redirect to the `auth_from_access_token` path.
- Otherwise, we make  a cross-region POST request with the known-good auth details, this will set a cookie, log the user in, and redirect them.

If no region authenticates successfully:
- We make the form's original POST request, and let the server display an error as normal.
*/

export default class extends Controller {
  static values = {
    scopes: String,
    baseUrls: Array,
    apiPath: String,
    authPath: String,
  }
  static targets = ["form", "email", "password"]

  async attemptSubmit(event) {
    event.preventDefault()

    // If we know the form isn't valid,
    // then we know login will fail
    if (!this.formTarget.reportValidity()) {
      return
    }

    disableSubmit(event.target)

    const authAttempt = async (base) => {
      const url = new URL(this.apiPathValue, base)

      return await Request.post(url, {
        username: this.emailTarget.value,
        password: this.passwordTarget.value,
        grant_type: "password",
        scope: this.scopesValue,
      }).then((res) => ({
        base: base,
        token: res.data.access_token,
      }))
    }

    // iterate over the possible URLs until we find one that doesn't throw
    // TODO experiment with doing this concurrently, https://github.com/TandaHQ/employee/blob/4de134a2834161f7abb18effd46bf40763c72203/src/util/any.js
    try {
      const auth = await this.baseUrlsValue.reduce(async (p, url) => p.catch(() => authAttempt(url)), Promise.reject())

      if (window.nativeProvideUserAuthDetails) {
        // If the native app is handling auth, we do nothing further on the client side.
        // See the `auth_from_access_token` action for next steps.
        window.nativeProvideUserAuthDetails(auth)
      } else {
        this.formTarget.action = new URL(this.authPathValue, auth.base).href
        this.formTarget.requestSubmit()
      }
    } catch (e) {
      if (process.env.NODE_ENV !== "production") {
        console.log(e)
      }
      // Do nothing special if auth fails, we just submit the form and let the server handle it
      this.formTarget.requestSubmit()
    }
  }
}
