/* global VanillaCalendar */

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["start", "end", "input"]
  static values = {
    start: String,
    end: String,
  }

  connect() {
    const startTarget = this.startTarget
    const endTarget = this.endTarget
    const inputTarget = this.inputTarget
    const options = {
      input: true,
      type: "multiple",
      months: 2,
      jumpMonths: 1,
      settings: {
        selection: {
          day: "multiple-ranged",
        },
        range: {
          edgesOnly: true,
        },
        selected: {
          dates: [`${this.startValue}_${this.endValue}`],
        },
        visibility: {
          theme: window.localStorage.getItem("DARK_MODE") === "1" ? "dark" : "light",
          daysOutside: false,
        },
      },
      actions: {
        changeToInput(e, self) {
          const [start, end] = self.selectedDates
          startTarget.value = start
          endTarget.value = end || start

          const formatDate = (date) =>
            new Intl.DateTimeFormat("en-GB", {
              day: "2-digit",
              month: "short",
              year: "numeric",
            }).format(new Date(date))

          const formattedStart = formatDate(start)
          const formattedEnd = formatDate(end || start)

          inputTarget.value = `${formattedStart} - ${formattedEnd}`
        },
      },
    }

    const calendar = new VanillaCalendar("#calendar", options)
    calendar.init()
  }
}
