// @flow

import Airbrake from "airbrake-js"

const shouldNotify = process.env.TARGET_RAILS_ENV === "staging" || process.env.TARGET_RAILS_ENV === "production"
const testMode = process.env.NODE_ENV === "test"

const config = {
  projectId: 149565,
  projectKey: "ea0f2b716ecffa36a660bee87d63a143",
}

type Backtrace = {
  file: string,
}
type Message = {
  backtrace: Array<Backtrace>,
  message: string,
}
type Notice = {
  context: { environment: string },
  errors: Array<Message>,
}

const filter = (notice: Notice) => {
  if (notice.errors && notice.errors.length) {
    const message = notice.errors[0].message

    // seems to be a chromium bug we don't really care about/can't help anyway
    // https://bugs.chromium.org/p/chromium/issues/detail?id=590375
    if (message.indexOf("__gCrWeb.autofill.extractForms") > -1) {
      return null
    }

    // Occurs due to Android webview: https://groups.google.com/a/chromium.org/g/chromium-dev/c/zVO57FQFydg
    // https://tanda.airbrake.io/projects/149565/groups/3611669708404937263?tab=overview

    if (message.indexOf("Background Sync is disabled") > -1) {
      return null
    }

    // Really spammy error thrown in axios
    // https://tanda.airbrake.io/projects/149565/groups/2113058864516815902/notices/3756444986480783017?tab=overview

    if (message.indexOf("Request failed with status code") > -1) {
      return null
    }

    // Maininit function is undefined in hotwire
    if (message.indexOf("maininit is not defined") > -1) {
      return null
    }

    // This has been happening forever with decent volume and has not been a problem for customers
    if (message.indexOf("$ is not defined") > -1) {
      return null
    }

    // don't think we can do much about this
    // https://tanda.airbrake.io/projects/149565/groups/2155027844022132167?tab=overview
    // https://tanda.airbrake.io/projects/149565/groups/2161259046940958001
    // https://tanda.airbrake.io/projects/149565/groups/2161259046940958001?tab=overview
    if (message.indexOf("__firefox__") > -1) {
      return null
    }

    const backtrace = notice.errors[0].backtrace || []
    // this will probably never happen, but it makes the rest of the logic more neat
    if (!backtrace.length) {
      return notice
    }

    const firstFile = backtrace[0].file
    const lastFile = backtrace[backtrace.length - 1].file

    // this error is being raised from HTML. probably from an inline script that is meant to init some other tanda JS.
    // this would fail if one of the scripts in our <head> fails to load. unfortunately there is not much we can do about
    // that here.
    if (backtrace.length === 1 && firstFile.indexOf(".js") < 0) {
      notice.context.environment = [process.env.TARGET_RAILS_ENV, "InlineScriptFailure"].join("-")
    }

    // intercom uses react and there's always random errors it's throwing that we don't care about
    if (firstFile.indexOf("js.intercomcdn.com") > -1) {
      return null
    }

    // ignore errors from the marketing site.
    // eg. https://tanda.airbrake.io/projects/149565/groups/2041818015645029303?tab=notice-detail
    if (firstFile.indexOf("wp-includes") > -1 || firstFile.indexOf("wp-content") > -1) {
      return null
    }

    // if you save a page to your hard drive then open it in your browser, not all the JS works (but for some reason airbrake does)
    // filters out things like https://tanda.airbrake.io/projects/149565/groups/2028014587991023226?tab=notice-detail
    // doesn't filter out things like https://tanda.airbrake.io/projects/149565/groups/1997893081474781555?tab=notice-detail
    if (lastFile.indexOf(".html") > -1) {
      return null
    }

    // ignore errors in some buggy chrome extensions
    // eg. https://tanda.airbrake.io/projects/149565/groups/2003197762027640868?tab=notice-detail
    if (lastFile.indexOf("netdefender/hui/ndhui.js") > -1) {
      return null
    }

    // ignore errors caused by Facebook crawlers
    // https://tanda.airbrake.io/projects/149565/groups/2293151680847666704?tab=notice-detail
    // https://tanda.airbrake.io/projects/149565/groups/2298417159958944235?tab=notice-detail
    //
    // More info:
    // https://stackoverflow.com/questions/52313444/how-can-i-stop-ismatchingdomain-reference-errors-coming-from-facebook-referrer
    // List of IPs that cause it (Owned by Facebook Ireland and Facebook USA)
    // https://discourse.snowplowanalytics.com/t/errors-ismatchingdomain-is-not-defined-and-datacloneerror/2328
    if (
      message === "_isMatchingDomain is not defined" ||
      message ===
        "Failed to execute 'postMessage' on 'Window': function n(t,e){return e>t?-1:t>e?1:t>=e?0:NaN} could not be cloned."
    ) {
      return null
    }

    // no action required, more info https://github.com/TandaHQ/payaus/pull/26348
    if (message.includes("AbortError:")) {
      return null
    }
  }

  if (shouldNotify) {
    return notice
  } else {
    // eslint-disable-next-line no-console
    console.log("sending to airbrake: " + JSON.stringify(notice.errors))
    return null
  }
}

// Please remove this if you work on this file
// eslint-disable-next-line flowtype/no-weak-types
export const generateNotifyFunction = (): ((args?: any) => void) | ((err: any) => any) => {
  if (testMode) {
    // eslint-disable-next-line flowtype/no-weak-types
    return function (args?: any) {} // no-op
  }

  const airbrake = new Airbrake(config)

  airbrake.addFilter(filter)

  airbrake.addFilter((notice) => {
    notice.context.environment = notice.context.environment || process.env.TARGET_RAILS_ENV
    notice.session.user = window.current_user
    return notice
  })

  // eslint-disable-next-line flowtype/no-weak-types
  return (err: any): any => airbrake.notify(err)
}

// Please remove this if you work on this file
// eslint-disable-next-line flowtype/no-weak-types
export const notify: ((args?: any) => void) | ((err: any) => any) = generateNotifyFunction()
