/* global SeekApi */

import { Controller } from "@hotwired/stimulus"
import Request from "helpers/request"
import Routes from "helpers/routes"

export default class AdSelectionPanelController extends Controller {
  static values = {
    nonce: String,
    profileId: String,
    authTokenUrl: String,
    seekLocation: String,
    seekOrganisation: String,
    jobTitle: String,
    workTypeCode: String,
  }

  static targets = [
    "form",
    "panelContainer",
    "brandingFrame",
    "keySellingPointsLimitField",
    "keySellingPointsLimitDisplay",
  ]

  static initialised = false

  async #loadScript() {
    const scriptElement = document.createElement("script")
    const promise = new Promise((resolve, reject) => {
      scriptElement.onload = resolve
      scriptElement.onerror = reject
    })
    scriptElement.nonce = this.nonceValue
    scriptElement.src = "https://integration.seek.com/panels/SeekApi.js"
    document.body.appendChild(scriptElement)

    AdSelectionPanelController.initialised = promise
    await promise
    AdSelectionPanelController.initialised = true

    scriptElement.remove()
  }

  async connect() {
    if (!AdSelectionPanelController.initialised) {
      // needs to be called from `connect` to have access to the nonce
      await this.#loadScript()
    } else if (AdSelectionPanelController.initialised instanceof Promise) {
      await AdSelectionPanelController.initialised
    }

    this.render()
  }

  render() {
    if (!AdSelectionPanelController.initialised) return

    const formData = new FormData(this.formTarget)

    // Empty array shows user-facing warning instead of internal error
    const jobCategory =
      (!formData.has("job_category") || formData.get("job_category") === "other"
        ? formData.get("other_job_category")
        : formData.get("job_category")) || []

    SeekApi.render(this.panelContainerTarget, "adSelection", {
      getAuthToken: async () => {
        const { data } = await Request.get(this.authTokenUrlValue)
        return data.token
      },
      onChange: (event) => {
        this.updateBranding({
          coverImageSupported: event.selectedProduct?.features.branding?.coverImageIndicator ?? false,
          logoSupported: event.selectedProduct?.features.branding?.logoIndicator ?? false,
        })

        const keySellingPointsLimit = event.selectedProduct?.features.searchBulletPoints?.limit ?? 0
        this.keySellingPointsLimitDisplayTarget.textContent = keySellingPointsLimit.toFixed(0)
        this.keySellingPointsLimitFieldTarget.value = keySellingPointsLimit
        this.keySellingPointsLimitFieldTarget.dispatchEvent(new Event("change"))
      },
      positionProfile: {
        profileId: this.profileIdValue || null,
        jobCategories: jobCategory,
        positionLocation: this.seekLocationValue,
        positionOrganizations: this.seekOrganisationValue,
        positionTitle: this.jobTitleValue || formData.get("title"),
        seekAnzWorkTypeCode: this.workTypeCodeValue || null,
      },
    })
  }

  updateBranding({ coverImageSupported, logoSupported }) {
    this.brandingFrameTarget.src = Routes.job_branding_ats_seek_index_path({
      cover_image_supported: coverImageSupported ? "yes" : "no",
      logo_supported: logoSupported ? "yes" : "no",
    })
  }
}
