import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  redirect(event) {
    const id = event.params.id
    const src = event.params.src
    const frame = document.getElementById(id)
    frame.setAttribute("src", src)
    frame.reload()
  }
}
