// @flow
import { StreamActions } from "@hotwired/turbo"

// https://marcoroth.dev/posts/guide-to-custom-turbo-stream-actions
StreamActions.scrollIntoView = function () {
  requestAnimationFrame(() => {
    const element = document.getElementById(this.getAttribute("target"))
    if (element) {
      element.scrollIntoView({ behavior: "smooth" })
    }
  })
}
