import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["category", "report", "productArea", "resultsMessage", "messageBody", "searchTerm"]

  search(e) {
    const searchValue = e.target.value.toLowerCase()
    if (searchValue === "") {
      this.resultsMessageTarget.classList.add("hidden")
    } else {
      this.resultsMessageTarget.classList.remove("hidden")
    }

    this.reportTargets.forEach((report) => {
      const reportName = report.dataset.value.toLowerCase()
      if (reportName.includes(searchValue)) {
        report.classList.remove("hidden")
      } else {
        report.classList.add("hidden")
      }
    })

    // Handle categories
    this.categoryTargets.forEach((category) => {
      const allAreHidden = Array.from(category.querySelectorAll("li")).every((report) =>
        report.classList.contains("hidden")
      )
      if (allAreHidden) {
        category.classList.add("hidden")
      } else {
        category.classList.remove("hidden")
      }
    })

    // Handle product areas

    this.productAreaTargets.forEach((productArea) => {
      const allAreHidden = Array.from(productArea.querySelectorAll("section.category")).every((category) =>
        category.classList.contains("hidden")
      )
      if (allAreHidden) {
        productArea.classList.add("hidden")
      } else {
        productArea.classList.remove("hidden")
      }
    })

    // Get a count of the number of visible menu items and display
    const visibleMenuItemsCount = this.reportTargets.filter((item) => !item.classList.contains("hidden")).length
    this.messageBodyTarget.innerText = `${visibleMenuItemsCount} ${
      // eslint-disable-next-line no-undef
      visibleMenuItemsCount === 1 ? I18n.t("js.settings.show.search.result") : I18n.t("js.settings.show.search.results")
    }`
    this.searchTermTarget.innerText = searchValue
  }
}
