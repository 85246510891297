// @flow
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets: Array<string> = [
    "optInCheckbox",
    "optOutCheckbox",
    "postponeAutomaticEnrolmentCheckbox",
    "postponeAutomaticEnrolmentDate",
    "postponeDateField",
  ]

  connect() {
    if (this.postponeDateFieldTarget.value !== "") {
      this.postponeAutomaticEnrolmentCheckboxTarget.checked = true
      this.postponeAutomaticEnrolmentDateTarget.classList.remove("hidden")
    }
  }
  optInUncheckOtherBoxes() {
    if (this.optInCheckboxTarget.checked) {
      this.optOutCheckboxTarget.checked = false
      this.postponeAutomaticEnrolmentCheckboxTarget.checked = false
      this.postponeAutomaticEnrolmentDateTarget.classList.add("hidden")
      this.postponeDateFieldTarget.value = ""
    }
  }

  optOutUncheckOtherBoxes() {
    if (this.optOutCheckboxTarget.checked) {
      this.optInCheckboxTarget.checked = false
      this.postponeAutomaticEnrolmentCheckboxTarget.checked = false
      this.postponeAutomaticEnrolmentDateTarget.classList.add("hidden")
      this.postponeDateFieldTarget.value = ""
    }
  }

  postponeUncheckOtherBoxes() {
    if (this.postponeAutomaticEnrolmentCheckboxTarget.checked) {
      this.optOutCheckboxTarget.checked = false
      this.optInCheckboxTarget.checked = false
      this.postponeAutomaticEnrolmentDateTarget.classList.remove("hidden")
    } else {
      this.postponeDateFieldTarget.value = ""
      this.postponeAutomaticEnrolmentDateTarget.classList.add("hidden")
    }
  }
}
